(function ($) {

    function getUrlParameter(name) {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
        var results = regex.exec(location.search);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    };

    let $body = $('body');
    let progress = $('.step-progress');

    let sections = $('.step-progress-section');

    let trialPackageButtons = $('a[data-trial-package]');

    trialPackageButtons.on('progress-shaft-changed', function (event, variations) {
        let button = $(this);
        let week = button.data('trial-package');

        button.attr('href', variations[week]['add_to_cart_checkout_url']);
    });

    $('.btn[data-variations]').on('click', function () {
        trialPackageButtons.trigger('progress-shaft-changed', $(this).data('variations') || '#');
    });


    let next = $('.is-next.is-progress').on('click', function (e) {
        e.preventDefault()

        let button = $(this)
        let current = progress.find('li.is-current');
        let next = current.next();

        current.removeClass('is-current');
        next.addClass('is-complete is-current');

        if (next.data('progress-class')) {
            progress.addClass(next.data('progress-class'));
        }

        sections.removeClass('is-active').next().addClass('is-active');
    });

    /**
     * Specific logic to skip the first step in case a shaft color
     * has been set in the URL.
     *
     * We are always showing a loader, and we will always remove hide the loader
     * after load.
     */
    (function () {
        const shaftColor = getUrlParameter('color')
        if (shaftColor) {
            $('.btn[data-shaft="' + shaftColor + '"]').click()
        } else {
            $('section.trial-start-section').removeClass('invisible')
        }

        $('.is-wizard-loader').removeClass('is-loading')
    })()
})(jQuery);
